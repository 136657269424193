<template>
  <div class="page-foot">
    <div class="top">
      <div class="w1200 content">
        <div class="left">
          <div class="top-info">
            <img src="https://dummyimage.com/125x125/00bc12" alt="" srcset="" />
            <div class="contact">
              <div class="title">咨询热线</div>
              <div class="tel">0000 - 00000000</div>
              <div class="time">周一至周日 8:00-23:00</div>
              <div class="tags">
                <span>
                  <i class="el-icon-platform-eleme"></i>
                </span>
                <span>
                  <i class="el-icon-s-help"></i>
                </span>
                <span>
                  <i class="el-icon-picture"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="bottom-info">
            <span>友情链接：</span>
            <el-link style="margin: 0 10px" :underline="false">应必客</el-link>
            <el-link :underline="false">应必客</el-link>
          </div>
        </div>
        <div class="right">
          <dl>
            <dt>交易保障</dt>
            <dd><el-link :underline="false">担保交易</el-link></dd>
            <dd><el-link :underline="false">争议仲裁</el-link></dd>
            <dd><el-link :underline="false">用户防骗</el-link></dd>
          </dl>
          <dl>
            <dt>关于我们</dt>
            <dd><el-link :underline="false">平台简介</el-link></dd>
            <dd><el-link :underline="false">加入我们</el-link></dd>
            <dd><el-link :underline="false">联系我们</el-link></dd>
          </dl>

          <dl>
            <dt>服务保障</dt>
            <dd><el-link :underline="false">隐私保护</el-link></dd>
            <dd><el-link :underline="false">免责声明</el-link></dd>
            <dd><el-link :underline="false">用户协议</el-link></dd>
          </dl>

          <dl>
            <dt>特色服务</dt>
            <dd><el-link :underline="false">软件商城</el-link></dd>
            <dd><el-link :underline="false">预约服务商</el-link></dd>
            <dd>
              <el-link :underline="false">发布需求</el-link>
            </dd>
          </dl>

          <dl>
            <dt>操作指南</dt>
            <dd>
              <el-link :underline="false">帮助中心</el-link>
            </dd>
            <dd><el-link :underline="false">新闻资讯</el-link></dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="w1200 content">
        <span>Copyright 2015 - 2021 in-peak</span>

        <a
          style="margin-left: 15px; color: #999; font-size: 14px"
          href="//beian.miit.gov.cn/"
          target="_blank"
          >粤ICP备17009520号-3</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-foot",
};
</script>

<style lang="scss" scoped>
.page-foot {
  .top {
    background-color: #fff;
    height: 200px;
    padding-top: 13px;
    padding-bottom: 18px;

    .content {
      display: flex;
      align-items: flex-start;
    }

    .left {
      width: 325px;
      border-right: 1px solid #f4f5f9;

      .top-info {
        padding-bottom: 5px;
        width: 280px;
        border-bottom: 1px dashed #f4f5f9;
        display: flex;
        align-items: flex-start;
        img {
          margin-top: 15px;
          width: 125px;
          height: 125px;
          margin-right: 20px;
        }

        .contact {
          margin-top: 20px;
          .title {
            font-size: 14px;
            font-weight: 700;
            color: #252537;
          }

          .tel {
            margin-top: 9px;
            font-size: 16px;
            font-weight: 700;
            color: #4c495d;
          }

          .time {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            color: #4c495d;
          }

          .tags {
            margin-top: 10px;
            span {
              margin-right: 10px;
              cursor: pointer;
              i {
                font-size: 24px;
                color: #1989fa;
              }
            }
          }
        }
      }

      .bottom-info {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
      }
    }

    .right {
      flex: 1 1 auto;
      margin-left: 65px;
      padding-top: 25px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      dl {
        display: inline-block;
        margin: 0;

        vertical-align: top;
        dt {
          margin: 0;
          font-size: 14px;
          font-weight: 700;
          color: #252537;
          margin-bottom: 17px;
          dd {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 14px;
          }
        }
      }
    }
  }
  .bottom {
    background: #2a303c;
    height: 50px;
    line-height: 50px;
    color: #fff;
    .content {
      cursor: pointer;
      height: 100%;
    }
    span {
      opacity: 0.4;
      font-size: 12px;
    }
  }
}
</style>