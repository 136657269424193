<template>
	<div class="or-code">
		<div class="back">
			<div class="content" @mouseenter="wechat=true" @mouseleave="wechat=false">
				<img src="@/assets/images/homePage/public.png" alt=""/>
				<div class="desc">公众号</div>
			</div>
			<img src="@/assets/images/homePage/wechat.webp" alt="" class="qr" v-if="wechat"/>
		</div>
		<div class="back">
			<div class="content" @mouseenter="smallProgram=true" @mouseleave="smallProgram=false">
				<img src="@/assets/images/homePage/smallProgram.png" alt="" />
				<div class="desc">小程序</div>
			</div>
      <img src="@/assets/images/homePage/wechat.webp" alt="" class="qr" v-if="smallProgram"/>
		</div>
		<div class="back">
			<div class="content" @mouseenter="app=true" @mouseleave="app=false">
				<img src="@/assets/images/homePage/app.png" alt="" />
				<div class="desc">APP</div>
			</div>
      <img src="@/assets/images/homePage/wechat.webp" alt="" class="qr" v-if="app"/>
		</div>
		<div class="back">
			<div class="content" @mouseenter="service=true" @mouseleave="service=false">
				<img src="@/assets/images/homePage/service.png" alt="" />
				<div class="desc">联系客服</div>
			</div>
      <img src="@/assets/images/homePage/wechat.webp" alt="" class="qr" v-if="service"/>
		</div>
		<el-backtop :visibility-height="0" :right="0" :bottom="-50">
			<div class="back totop">
				<div class="content">
					<img src="@/assets/images/homePage/backTop.png" alt="" />
				</div>
			</div>
		</el-backtop>
	</div>
</template>

<script>
export default {
  data(){
    return{
      wechat:false,
      smallProgram:false,
      app:false,
      service:false
    }
  }
}
</script>

<style scoped lang="scss">
.or-code {
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 9999;
	.el-backtop {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 56px;
	}
	.back {
		width: 56px;
		height: 56px;
		line-height: 33px;
		background: #327bfa;
		border-radius: 2px 0 0 2px;
		z-index: 100;
		text-align: center;
		cursor: pointer;
		margin-top: 10px;
		.content {
			img {
				width: 26px;
				height: 26px;
			}
			.desc {
				width: 56px;
				height: 18px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #fff;
				line-height: 18px;
			}
		}
		.qr {
			position: relative;
			width: 150px;
			height: 150px;
			top: -51px;
			right: 150px;
		}
	}
	.totop {
		background-color: #fff;
		line-height: 56px;
		width: 56px;
	}
}
</style>
