<template>
	<div class="preview">
		<ori-header />
		<div class="preview-content">
			<div class="preview-top">
				<img src="@/assets/images/homePage/logo.webp" alt="" />
				<div class="preview-search">
					<el-input
						placeholder="搜索关键词、行业、公司等…（多个关键词可用空格分开）"
					>
						<el-button slot="append" icon="el-icon-search"></el-button>
					</el-input>
					<div class="hot-search">
						<span>热门搜索:</span>
						<span class="tag">元宇宙</span><span class="tag">电子烟</span>
						<span class="tag">机器人</span><span class="tag">用工成本</span>
						<span class="tag">Web3.0</span><span class="tag">专精特新</span>
					</div>
				</div>
			</div>
			<div class="preview-middle container">
				<div class="left">
					<div class="top-header">
						<div class="title">
							2021年新能源汽车行业报告（蔚来，小鹏，理想，车企）
						</div>
						<div class="label">
							<span>工业制造</span>
							<span>2021-11-24</span>
							<span>机构发布</span>
							<span>洞见研报</span>
						</div>
					</div>
					<div class="preview-img">
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<img src="@/assets/images/homePage/banner3.png" alt="" />
						<!-- <div class="img-btn">
							点击关注微信公众号，查看完整报告
						</div> -->
					</div>
				</div>
				<div class="right">
					<div class="right-box">
						<div class="header-title">
							<span>你可能感兴趣</span>
						</div>
						<div class="list">
							<div class="list-item" v-for="i in 4" :key="i">
								<img src="@/assets/images/homePage/int.png" alt="" />
								<div class="info">
									<div class="title">
										2021年低代码行业研究报告（无代码技术开发，软件，SaaS）
									</div>
									<div class="state">
										科技传媒
									</div>
									<div class="author">
										洞见研报
									</div>
									<div class="time">
										<span>2022-01-04</span>
										<span>洞见研报</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<page-foot />
		<or-code />
	</div>
</template>

<script>
import PageFoot from '../../../components/ori-com/PageFoot.vue'
import OrCode from '../../../components/ori-com/Orcode.vue'
export default {
	components: {
		PageFoot,
		OrCode,
	},
}
</script>

<style scoped lang="scss">
.preview {
	background: #f7f7f7;
	.container {
		width: 1200px;
		margin: auto;
	}
	.preview-content {
		.preview-top {
			height: 200px;
			background: url(~@/assets/images/homePage/banner2.png);
			background-size: 100% 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 223px;
				height: 40px;
				margin-right: 68px;
				margin-bottom: 17px;
			}
			.preview-search {
				.el-input {
					width: 680px;
					height: 50px;
					::v-deep {
						.el-input__inner {
							height: 50px;
						}
					}
					.el-button {
						width: 80px;
						background-color: #327bfa;
						height: 50px;
						color: #fff;
						font-size: 20px;
						margin-right: -21px;
						border: none;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
				.hot-search {
					padding-top: 20px;
					height: 20px;
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #fff;
					line-height: 20px;
					span {
						margin-right: 20px;
					}
					.tag {
						cursor: pointer;
					}
					.tag:hover {
						color: #327bfa;
					}
				}
			}
		}
		.preview-middle {
			margin: 20px auto;
			display: flex;
			.left {
				background: #fff;
				width: 820px;
				margin-right: 20px;
				.top-header {
					padding: 20px;
					.title {
						width: 100%;
						height: 28px;
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #202a33;
						line-height: 28px;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: 16px;
					}
					.label {
						span {
							font-size: 12px;
							color: #a8b4bf;
							margin-right: 20px;
						}
						span:first-child {
							display: inline-block;
							padding: 3px;
							color: #d7403d;
							background: #fff1f0;
							border-radius: 4px;
						}
					}
				}
				.preview-img {
					border: 15px solid #a8b4bf;
					// border-bottom: 70px solid #a8b4bf;
					position: relative;
					img {
						width: 790px;
						// margin: 300px 0;
					}
					.img-btn {
						position: absolute;
						cursor: pointer;
						bottom: -55px;
						left: 230px;
						height: 40px;
						width: 320px;
						background-color: #327bfa;
						color: #fff;
						border-radius: 4px;
						font-size: 16px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						line-height: 40px;
						text-align: center;
					}
				}
			}
			.right {
				width: 360px;
				.right-box {
					background: #fff;
					.header-title {
						padding: 16px;
						font-size: 16px;
						margin-left: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #202a33;
						line-height: 22px;
						background: url(~@/assets/images/homePage/slash.png) no-repeat 0;
						background-size: 16px;
						span {
							margin-left: 10px;
						}
					}
					.list {
						padding: 0 16px;
						padding-bottom: 0;
						.list-item {
							border-bottom: 1px solid #eef2f6;
							display: flex;
							margin-top: 16px;
							img {
								width: 89px;
								height: 114px;
								-o-object-fit: cover;
								object-fit: cover;
							}
							.info {
								margin-left: 14px;
								.title {
									width: 225px;
									height: 44px;
									font-size: 14px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #202a33;
									line-height: 22px;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
									cursor: pointer;
								}
								.state {
									display: inline-block;
									color: #d7403d;
									background: #fff1f0;
									height: 22px;
									border-radius: 4px;
									padding: 2px 6px;
									font-size: 12px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									line-height: 18px;
									margin: 4px 0 6px 0;
									cursor: pointer;
								}
								.author {
									height: 18px;
									font-size: 12px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #a8b4bf;
									line-height: 18px;
								}
								.time {
									height: 18px;
									font-size: 12px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #a8b4bf;
									line-height: 18px;
									margin: 5px 0 20px;
									span {
										margin-right: 20px;
									}
								}
							}
						}
						.list-item:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}
</style>
